import Postgresql from "@iconify/icons-logos/postgresql";
import React from "@iconify/icons-logos/react";
import Angular from "@iconify/icons-logos/angular-icon";
import Firebase from "@iconify/icons-logos/firebase";
import Javascript from "@iconify/icons-logos/javascript";
import Node from "@iconify/icons-logos/nodejs-icon";
import Csharp from "@iconify/icons-logos/c-sharp";
import Vscode from "@iconify/icons-logos/visual-studio-code";
import Npm from "@iconify/icons-logos/npm";
import Nest from "@iconify/icons-logos/nestjs";
import Redux from "@iconify/icons-logos/redux";
import Webpack from "@iconify/icons-logos/webpack";
import Typescript from "@iconify/icons-logos/typescript-icon";
import Html from "@iconify/icons-logos/html-5";
import Css from "@iconify/icons-logos/css-3";
import Ionic from "@iconify/icons-logos/ionic";
import Rxjs from "@iconify/icons-logos/reactivex";
import Babel from "@iconify/icons-logos/babel";
import Express from "@iconify/icons-logos/express";
import Googlecloudfunctions from "@iconify/icons-logos/google-cloud-functions";
import Dotnet from "@iconify/icons-logos/dotnet";
import Git from "@iconify/icons-logos/git-icon";
import Jquery from "@iconify/icons-logos/jquery";
import Python from "@iconify/icons-logos/python";
import Django from "@iconify/icons-logos/django";
import Es6 from "@iconify/icons-logos/es6";

export const TechnologiesIcons = {
  Postgresql,
  React,
  Angular,
  Firebase,
  Javascript,
  Node,
  Csharp,
  Vscode,
  Npm,
  Nest,
  Redux,
  Webpack,
  Typescript,
  Html,
  Css,
  Ionic,
  Rxjs,
  Babel,
  Express,
  Googlecloudfunctions,
  Dotnet,
  Git,
  Jquery,
  Python,
  Django,
  Es6,
};
